* {
  margin: 0;
  padding: 0; }

html, body, #root {
  height: 100%;
  width: 100%; }

.app {
  text-align: center;
  background: black;
  color: white;
  height: 100%; }

.app.timerActive {
  background: #f7f7f7; }

.app-logo {
  height: 80px; }

.app-logo.animate {
  animation: App-logo-spin infinite 1s linear; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.app-header {
  background-color: #3b5998; }

.app-header,
.app-footer button {
  color: white; }

.app-header {
  font-size: 1.5em;
  height: 150px;
  padding: 20px; }

.time-container {
  font-size: 15em;
  margin: 0; }

.time-container.down {
  color: #3b5998; }

.time-container.down.ready {
  color: #00d8ff; }

.app.timerActive .time-container,
.app.timerActive .timelist,
.app.timerActive .stats {
  color: black; }

.time {
  font-family: Courier; }

.timelist, .stats {
  font-size: 1.5em; }

.timelist {
  padding-top: 3%; }

.timelist li {
  list-style: none;
  display: flex;
  justify-content: center; }

.timelist .time {
  text-align: left;
  width: 50%; }

.timelist button {
  width: 50%;
  text-align: right;
  color: #00d8ff;
  border: none;
  background: transparent; }

.stats {
  list-style: none;
  padding: 0;
  height: 60px;
  width: 100%; }

.stats li {
  float: left;
  width: 25%; }

.stats .stats-label {
  color: #00d8ff; }

.app-footer {
  display: none; }

.app-footer .button-container {
  align-self: center;
  border-left: 1px solid black;
  border-right: 1px solid black; }

.app-footer button {
  font-size: .75em;
  background-color: #3b5998;
  border: none;
  height: 100%;
  width: 100%; }

.button-label-icon {
  display: none; }

@media only screen and (max-width: 1024px) {
  h2 {
    margin: 0; }
  .app-logo {
    height: 40px; }
  .app-header {
    height: 13%;
    font-size: .8em;
    box-sizing: border-box; }
  .app-body {
    height: 80%;
    overflow-y: scroll; }
  .app-footer {
    display: flex;
    height: 7%; }
  .time-container {
    font-size: 7em; }
  .button-label-text {
    display: none; }
  .button-label-icon {
    display: inline;
    color: #00d8ff; }
  .app-footer .button-container {
    height: 100%; }
  .app-footer .button-container.one-third {
    width: 33.33333%; }
  .app-footer .button-container.one-half {
    width: 50%; }
  .timelist li {
    margin-bottom: 3%;
    padding: 0 20%; }
  * {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none; } }

@media only screen and (max-width: 667px) {
  .app-header {
    height: 17%;
    padding: 5px 20px; }
  .app-body {
    height: 76%; }
  .app-footer {
    height: 7%; }
  .time-container {
    font-size: 4em; }
  .stats {
    height: 132px; }
  .stats li {
    width: 50%;
    margin-bottom: 10px; } }

@media only screen and (max-width: 667px) and (orientation: landscape) {
  .app-header {
    height: 20%; }
  .app-body {
    height: 70%; }
  .app-footer {
    height: 10%; } }
